export const ENTER_YOUR_PASSOWRD ="Enter your password"
export const PLEASE_ENTER_YOUR_PASSWORD ="Please enter your password"
export const UNABLE_TO_LOGIN = "Oops! We couldn't log you in. Please inform your teacher"
export const ENTER_YOUR_FIRST_NAME ="Enter your first name"
export const ENTER_YOUR_LAST_NAME ="Enter your last name"
export const FIRST_NAME ="First name"
export const LAST_NAME = "Last name"
export const JOB_TITLE = "Job title"
export const FORGOT_PASSWORD = "Forgot password?"
export const DONT_HAVE_AN_ACCOUNT = "Don't have an account?"
export const SIGN_UP = "Sign Up"
export const SELECT_YOUR_JOB_TITLE = "Select your job title"
export const GRADES_TAUGHT="Grades Taught"
export const EMAIL = "Email"
export const ENTER_YOUR_SCHOOL_EMAIL ="Enter your school email"
export const SCHOOL_NAME ="School name"
export const ENTER_YOUR_SCHOOL_NAME ="Enter your school name"
export const PASSWORD ="Password"
export const CONFIRM_PASSWORD = "Confirm password"
export const ENTER_CONFIRM_PASSWORD = "Enter confirm password"
export const CURRENT_CURRICULUMS_USED ="Current curriculums used"
export const CURRENT_CURRICULUMS_USED_PLACEHOLDER ="Current Curriculum Used"
export const HOW_DID_YOU_HEAR_ABOUT_US ="How did you hear about us?"
export const HOW_DID_YOU_HEAR_ABOUT_US_PLACEHOLDER  ="How did you hear about us"
export const ALMOST_THERE ="Almost there!"
export const VERIFY_YOUR_ACCOUNT_USING_OTP ="Verify your account using OTP (One Time Password)"
export const ENTER_MOBILE_NUMBER_TO_RECEIVE_OTP ="Enter your mobile number below to receive a one time verification code."
export const MOBILE_NUMBER = "Mobile number"
export const ENTER_PHONE_NUMBER ="Enter phone number"
export const CONTINUE ="Continue"
export const VERIFICATION_CODE = "Verification code"
export const WE_HAVE_SENT_THE_VERIFICATION_CODE_TO_PHONE_NUMBER = "We have sent the verification code to the phone number you provided"
export const OTP = "OTP"
export const DIDNOT_RECEIVE_THE_VERIFICATION_OTP = "Didn’t receive the verification OTP?"
export const RESEND_CODE ="Resend code"
export const WE_ARE_REVIEWING_YOUR_REQUEST ="We’re reviewing your request."
export const EXPECT_AN_EMAIL_SOON ="Expect an email soon to"
export const WITH_NEXT_STEPS_TO_ACCESS_AAUTI_SCHOOL ="with next steps to access Aauti School."
export const CREATE_YOUR_FREE_ACCOUNT = "Create Your Free Account"





// error messages
export const PLEASE_ENTER_VALID_EMAIL_ADDRESS ="Please enter a valid email address"
export const PASSSWORD_LENGTH_BETWEEN_8_TO_20 ="Password lenght should be between 8 to 20 characters"
export const PASSWORDS_DONOT_MATCH ="Passwords do not match"
export const PASSWORDS_NEED_TO_MATCH ="Password needs to match"
export const VALID_PHONE_NUMBER_WITH_COUNTRY_CODE ="Please enter a valid phone number with country code."
export const PLEASE_ENTER_YOUR_NAME = "Please enter your first name"
export const FIRST_NAME_MUST_BE_ATLEAST_1_CHAR_LONG = "First name should have at least 1 character"
export const FIRST_NAME_MUST_BE_20_CHAR_LONG = " First name can't be more than 50 characters"
export const PLEASE_ENTER_YOUR_LAST_NAME = "Please enter your last name"
export const LAST_NAME_MUST_BE_ATLEAST_1_CHAR_LONG = "Last name should have at least 1 character"
export const LAST_NAME_MUST_BE_20_CHAR_LONG = " Last name can't be more than 50 characters"
export const SCHOOL_NAME_MUST_BE_AT_LEAST_3_CHAR_LONG = "School name can't be more than 75 characters "
export const PLEASE_CONFIRM_YOUR_PASSWORD = "Please confirm your password"
export const PLEASE_ENTER_YOUR_SCHOOL_EMAIL = "Please enter your school email"
export const PLEASE_ENTER_YOUR_SCHOOL_NAME = "Please enter your school name"
// export const SCHOOL_NAME_MUST_BE_3_CHAR_LONG = "School name must be at least 3 characters long"
export const PLEASE_ENTER_CURRICULUM_NAME = "Please enter curriculum name"
export const PLEASE_ENTER_YOUR_JOB_TITLE = "Please select your job title"
export const SELECT_GRADES = "Select grades you teach"