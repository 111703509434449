import React, { useEffect } from 'react'
import {AAUTI_LOCALHOST_URL, AAUTI_CLEVER_URL, domainBaseUrl} from "../config/config"

function CleverComponent(props: any) {
    const clientId = '7c051a4d38ecfe64c4d7'
    const clientSecret = "fcfb2c545f8f1e950063143d827d790f26491028"

    const getData = async(codeKey: string) => {
        const encodedString = btoa(clientId + ":" + clientSecret)
        const options = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Basic ${encodedString}`,
                mode: 'no-cors'
            },
            body: JSON.stringify({
                code: codeKey,
                "grant_type": "authorization_code",
                "redirect_uri": `${AAUTI_LOCALHOST_URL}`
            })
        }
        // const tokenData = await fetch("https://clever.com/oauth/tokens", options)
        const tokenData = await fetch(`${AAUTI_CLEVER_URL}/tokens`, options)
        const res = await tokenData.json()
        console.log('ressssss', res)
    }

    const onClickCall = async() => {
        console.log('calllllled')
        const encodedString = btoa(clientId + ":" + clientSecret)
        const options = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Basic ${encodedString}`,
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify({
                code: props.location?.state?.codeKey,
                "grant_type": "authorization_code",
                 "redirect_uri": `https://dev.${domainBaseUrl}/`
                // "redirect_uri": "http://localhost:3000/"
            })
        }
        // const tokenData = await fetch("https://clever.com/oauth/tokens", options)
        const tokenData = await fetch(`${AAUTI_CLEVER_URL}/tokens`, options)
        const res = await tokenData.json()
        console.log('ressssss onClickCall', res)

    }

    useEffect(() => {
        let key = props.location?.state?.codeKey
        if(key) {
            console.log('keeyy', key)
            setTimeout(() => {
                // getData(key)
            }, 1000)
        }
        console.log('^^^^^^^^^', props.location?.state?.codeKey, props)
        

    }, [props?.location?.state?.codeKey])

    console.log('propsss', props)

  return (
    <div>
        <h1>CleverComponent</h1>
        <div onClick={onClickCall}>
            <button onClick={onClickCall}>Call</button></div>
    </div>
  )
}

export default CleverComponent