import React from "react";
import { Box } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

interface IProps {
  onClick: any;
  disabled?: boolean;
  iconStyles?: any;
  mainBoxStyles?:any;
  buttonstyles?:any
}

const StyledCloseButton = ({ onClick, disabled ,iconStyles,mainBoxStyles,buttonstyles}: IProps) => {
  return (
    <Box
      sx={{
        zIndex: 10, // Ensures it stays on top of the modal content
        ...mainBoxStyles,
      }}
    >
      <button
        style={{
            ...buttonstyles,
          borderRadius: "50%",
          backgroundColor: disabled ? "grey" : "transparent", // Dynamic background
          border: "none",
          height:"20px",
          width:"20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: disabled ? "not-allowed" : "pointer",
          transition: "background-color 0.3s ease", // Smooth hover effect
        }}
        onClick={onClick}
        disabled={disabled}
      >
        <CancelIcon
          sx={{
            color: "#3166ba", // Icon color
            height: "25px", // Responsive scaling for the icon
            width:"25px",
            ...iconStyles
          }}
        />
      </button>
    </Box>
  );
};

export default StyledCloseButton;
