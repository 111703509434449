
import React from "react"
import { Box, Typography, Modal, Paper, Avatar, Divider } from '@mui/material';
import StyledCloseButton from "./StyledCloseButton";



interface IExamModal {
    children: any;
    modalState?: any;
    handleClose?: any;
    modalTitle?: String;
    modalWidth?: String;
    size?: any;
    ref?:any
    leftImage?: any;
    leftIcon?: any;
    leftImageSrc?: any;
    leftHeaderText?: any;
    closeIconOnclick?: any
    closeIcon?: boolean;
    searchBar?: any;
    showSearchField?: any;
    onChangeSearch?: any;
    onCloseSearch?: any;
    openSearchImage?: any
    showSearchFieldTrue?: any;
    searchInput?: any;
    setSearchInput?: any;
    setShowSearchField?: any;
    closeIconId?: any;
    searchInconId?: any;
    setShowSearchFieldTrue?: any
    sxheaderTextStyles?:any;
    rightText?:any

}


const CustomModal = ({ children,ref, rightText,  leftHeaderText, sxheaderTextStyles, closeIcon,  closeIconOnclick, modalState,  leftImage, leftIcon, modalWidth,}: IExamModal) => {
    const modalStyle: any = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: modalWidth ? modalWidth : "75%",
        minWidth: '320px',
        maxWidth: "880px",
        bgcolor: 'background.paper',
        borderRadius: '6px',
        boxShadow: 24,
    };

    return (
        <Modal open={modalState} ref={ref}>
            <>
                <Box sx={modalStyle}>
                    <Box sx={{ display: "flex", flexDirection: 'row', alignItems: 'center', height: "50px", justifyContent: 'space-between',p:1, m: 1 }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: closeIcon ? "96%" : "100%",
                            }}
                        >
                            <Box sx={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                                {leftImage ? (
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            height: '40px',
                                            width: '95px',
                                            backgroundColor: "#fff",
                                            display: "flex",
                                        }}
                                    >
                                        {typeof leftImage === "string" ? (
                                            <img
                                                src={leftImage}
                                                alt="Header SVG"
                                                style={{ height: "16px", width: "16px", objectFit: "contain" }}
                                            />
                                        ) : (
                                            leftImage // Pass the SVG React component directly
                                        )}
                                    </Avatar>
                                ) : leftIcon ? (
                                    React.cloneElement(leftIcon, {
                                        style: { fontSize: 40, color: "gray" }, // Customize icon styles
                                    })
                                ) : null}
                                {/* Left header texts */}
                                {leftHeaderText && <Typography sx={[{ ml: leftImage ? 1 : 0,...sxheaderTextStyles }]}>{leftHeaderText}</Typography>}
                            </Box>
                            {rightText&& <Typography sx={[ { ml: leftImage ? 1 : 0,...sxheaderTextStyles }]}>{rightText}</Typography>}
                        </Box>
                        {closeIcon && // Clsoing icon prop to close the modal and everything.
                            <Box sx={{ width: "3%", justifyContent: 'center' }}>
                                <StyledCloseButton onClick={closeIconOnclick} />
                            </Box>
                        }
                    </Box>
                    <Divider />
                    <Box>
                        {children}
                    </Box>

                </Box>
            </>

        </Modal>

    )
}

export default CustomModal

